import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                if (resp?.data?.success) {
                    const { access_token } = resp.data.data
                    dispatch(onSignInSuccess(access_token))
                        if (resp.data.data.info.role == "ADMIN") {
                            dispatch(setUser(resp.data.data.info))
                            navigate("/admin/dashboard")
                        } else if (resp.data.data.info.role == "MARKETER") {
                            dispatch(setUser(resp.data.data.info))
                            navigate("/marketer/dashboard")
                        } else {
                            dispatch(setUser(resp.data.data.info))
                            navigate("/dashboard")
                        }
                        
                    
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // navigate(
                    //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    // )
                    return {
                        status: 'success',
                        message: '',
                    }
                } else {
                    return {
                        status: 'failed',
                        message: resp.data.message,
                    }
                }

            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            password: values.password,
            billingType: values.billingType
        }
        try {
            const resp = await apiSignUp(payload)
            if (resp.data.success) {
                // const { token } = resp.data.data.user
                // dispatch(onSignInSuccess(token))
                // if (resp.data.data.user) {
                //     dispatch(setUser(resp.data.data.user))
                // }

                // timer
                setTimeout(() => {
                    // toast.push(
                    //     <Notification type="success">{'Registration Successful'}</Notification>
                    // );
                    
                    // save email to local storage
                    localStorage.setItem('emailForSignIn', values.email)
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // navigate to verify page
                    navigate("/verify-otp")
                }, 1000);

                return {
                    status: 'success',
                    message: resp.data.message,
                }
                
            } else {
                return {
                    status: 'failed',
                    message: resp.data.message,
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        // navigate(appConfig.unAuthenticatedEntryPath)
        // navigate("/")
        window.location.href = "/"
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
